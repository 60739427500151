.button {
    cursor: pointer;
    font-size: 1rem;
    height: 48px;
    border-radius: 12px;
    padding: 0 20px;
    transition: background-color .3s ease-in-out;
}

.normal {
    background-color: #01579b;
    color: white;
    border: none;
}

.normal:hover {
    background-color: #0277bd;
    transition: background-color .3s ease-in-out;
}

.outline {
    font-weight: 500;
    margin-right: 12px;
    background-color: var(--outline-btn-bg);
    color: var(--outline-btn-text);
    border: solid 1px #01579b;
}

.outline:hover {
    background-color: var(--outline-btn-hover);
    transition: background-color .3s ease-in-out;
}

@media screen and (max-width: 450px) {
    .sm {
        font-size: 0.8rem;
        height: 36px;
    }
}

