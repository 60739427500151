header {
    position: relative;
    width: 100%;
    z-index: 5;
    padding: 16px 0 8px;
    margin: 0px 0 20px;
}

.headerContainer {
    margin: 0 auto;
    max-width: 1920px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.navHide {
    display: none;
}   


.logo {
    width: 240px;
}

.logo a {
    text-decoration: none;
    color: var(--bn-text)
}

.logoIcon {
    font-weight: 700;
    float: left;
    line-height: 48px;
    font-size: 1.3rem;
    background-color: #01579b;
    padding: auto;
    color: white;
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.logoName {
    margin-top: 8px;
    font-size: 1.3rem;
    font-weight: 400;
}

.logoName span {
    font-weight: 600;
}

.navbar {
    display: flex;

}

.open {
    height: 133px;
}

.close {
    height: 80px;
}

.toggles {
    display: none;
}


@media screen and (max-width:900px) {
    ul {
        padding: 0
    }

    header {
        box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, .3);
        position: relative;
        top: 0;
        transition: all .3s ease-in-out;
        margin: 0 0 20px;
    }
    
    .navbar {
        display: none;
    }

    .toggles {
        display: flex;
        flex-direction: row;
    }

    .bars {
        color: #01579b;
        font-size: 1.5rem;
        cursor: pointer;
    }

    @keyframes prueba {
        0% {top:-73px;
            opacity: 0;        
        }
        40% {opacity: 0;}

        100% {top:0px;
            opacity: 1;}
    }

    .navHide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0 0;
        animation: prueba 0.3s ease-in-out
    }

}

.headerContainer li {
    cursor: pointer;
    background-color: var(--bgColor);
    z-index: 5;
    color: var(--link-color);
    line-height: 48px;
    list-style-type: none;
    margin: 0 16px;
    transition: color .3s ease-in-out;
}

.headerContainer li:hover {
    line-height: 48px;
    color: var(--link-hover-color);
    list-style-type: none;
    margin: 0 16px;
    transition: color .3s ease-in-out;
}

.navHide li {
    cursor: pointer;
    color: var(--link-color);
    line-height: 48px;
    list-style-type: none;
    margin: 0 16px;
    transition: color .3s ease-in-out;
}

.navHide li:hover {
    line-height: 48px;
    color: var(--link-hover-color);
    list-style-type: none;
    margin: 0 16px;
    transition: color .3s ease-in-out;
}

.headerContainer li:last-child {
    margin: 0 0 0 16px;
}

@media screen and (max-width:450px) {
    .logoIcon {
        font-weight: 700;
        float: left;
        line-height: 36px;
        font-size: 1.05rem;
        background-color: #01579b;
        padding: auto;
        color: white;
        width: 36px;
        height: 36px;
        border-radius: 100%;
    }

    .headerContainer {
        margin-top: 2px;
    }

    .navHide {
        padding-top: 4px;
    }

    .logoName {
        margin: 6px 0 0 0;
        font-size: 1.1rem;
    }

    .logo {
        width: 200px
    }

    .navHide li {
        font-size: 0.8rem;
    }

    .open {
        padding-top: 8px;
        height: 105px;
    }
    
    .close {
        padding-top: 8px;
        height: 60px;
    }
}