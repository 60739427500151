.footer {
    width: 100%;
    border-top: 1px solid var(--bg-color-secondary);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.footerContainer {
    margin: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer li {
    margin: 12px 48px 12px 0;
}

.footer a {
    font-size: 2rem;
    color: #AAA;
    transition: all .3s ease-in-out;
}

.footer a:hover {
    font-size: 2rem;
    color: #01579b;
    transition: all .3s ease-in-out;
}

.github:hover {
    font-size: 2rem;
    color: #6e5494 !important;
    transition: all .3s ease-in-out;
}

.linkedin:hover {
    font-size: 2rem;
    color: #0077b5 !important;
    transition: all .3s ease-in-out;
}

.footer ul{
    display: flex;
    justify-content: space-between;
    padding: 0
}

.footerContainer p {
    border: 0;
    text-align: center;
    font-size: 0.9rem;
    color: var(--secondary-text)
}

@media screen and (max-width:768px) {
    .footerContainer {
        flex-direction: column;
    }

    .footer li{
        margin: 12px 24px 0 24px;
    }

    .footerContainer p {
        margin: 8px 0 16px;
    }
}