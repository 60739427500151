body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --bgColor: #f5f5f5;
  --bg-color-secondary: #e5e5e5;
  --bg-color-card: #f5f5f5;
  --bg-color-card-secondary: #f5f5f5;
  --img-bg: #f5f5f5;
  --img-border: white;
  --img-shadow: rgba(0, 0, 0, .15);
  --shadow: rgba(0, 0, 0, .08);
  --shadow-hover: rgba(0, 0, 0, .15);
  --primary-text: #333;
  --secondary-text: #666;
  --link-color: #1111118c;
  --link-hover-color: #111;
  --outline-btn-bg: transparent;
  --outline-btn-hover: #01579b0a;
  --outline-btn-text: #01579b;
  --bn-text: #222;
  --primary-accent: #01579b;
  --secondary-accent: #01579b;
}


[data-theme='dark'] {
  --bgColor: #222;
  --bg-color-secondary: #444;
  --bg-color-card-secondary: #282828;
  --bg-color-card: #282828;
  --img-bg: #c7c7c7;
  --img-border: rgb(68,68,68);
  --img-shadow: rgba(0, 0, 0, .45);
  --shadow: rgba(0, 0, 0, .35);
  --shadow-hover: rgba(0, 0, 0, .40);
  --primary-text: #f5f5f5;
  --secondary-text: #b5b5b5;
  --link-color: #f5f5f58c;
  --link-hover-color: #f5f5f5;
  --outline-btn-bg: rgb(45, 45, 45);
  --outline-btn-hover: rgb(65, 65, 65);
  --outline-btn-text: #f5f5f5;
  --bn-text: #f5f5f5;
  --primary-accent: #007ced;
  --secondary-accent: #f5f5f5;
}