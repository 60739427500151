.whatido {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    height: auto; 
    max-width: 1024px;
}

.title h2 {
    position: relative;
    padding-bottom: 3px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
}

.title h2:before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0px;
    background-color: #DDD;
}

.title h2:after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 30%;
    height: 2px;
    bottom: 0px;
    background-color: #01579b;
}

.blockTitle {
    padding-left: 16px;
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 24px;
}

.fragment {
    width: 100%
}

.WIDGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 0 72px
}

.ProjectCont {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    align-items: center;
    padding: 0 16px;
    width: 100%;
    max-width: 1024px;
    margin-bottom: 16px;

}

@media screen and (max-width: 768px) {
    .WIDGrid {
        grid-template-columns:  1fr;
    }
}