.App {
  text-align: center;
  background-color: var(--bgColor);
  transition: background-color .3s ease-in-out;
}


html {
  scroll-behavior: smooth;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
li {
  list-style-type: none;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

  /* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #01579b #f5f5f5;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
}

*::-webkit-scrollbar-track {
  background: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  opacity: 0;
  background-color: #777;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

*::-webkit-scrollbar-thumb:hover {
  opacity: 0;
  background-color: #01579b;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
}

*::-webkit-scrollbar-thumb:active {
  opacity: 0;
  background-color: #01579b;
  border-radius: 20px;
  border: 2px solid #f5f5f5;
}

* {
  -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer */
   -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

/* --bs-gray-700: #5a5b75;
    --bs-gray-800: #4a4b65;
    --bs-gray-900: #37384e; */