.presentation {
    display: flex;
    position: relative;
    top: -100px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-x:unset;
}

.mainCont {
    margin: 0px 0;
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainImg {
    transition: background-color .3s linear, border .3s linear, box-shadow .3s linear;
    background-color: var(--img-bg);
    justify-self: center;
    width: 100%;
    max-width: 400px;
    border: 20px solid var(--img-border);
    border-radius: 100%;
    flex-grow: 1;
    box-shadow: 2px 2px 30px 0px var(--img-shadow)
}

.mainText {
    padding: 40px 0 0 40px;
    flex-grow: 2;
    text-align: left;
    color: var(--primary-text)
}

.mainText h1 {
    font-size: 3rem;;
}

.triangle {
    width: 0;
    height: 0;
    margin: 0 0 12px 6px;
    border-top: 14px solid #01579b; 
    border-right: 14px solid transparent;      
}

.mainText h6 {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--secondary-text);
    margin: 0 0 24px 0;
}

.mainText p {
    display: inline;
    padding: 4px 8px;
    margin-bottom: 48px;
    font-weight: 400;
    border: 0;
    color: white;
    background-color: #01579b;
}

h1 span {
    margin: 0;
    font-weight: 300;
}


@media screen and (max-width: 768px) {
    .presentation {
        position: relative;
        height: calc(100vh - 70px);
        top: -40px
    }

    .mainImg {
        width: 70%;
        border: 15px solid var(--img-border);
    }

    .mainText h1 {
        font-size: 2.4rem;
    }

    .mainText h6 {
        font-size: 1.12rem;
    }

    .mainText p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:450px) {
    .mainText {
        padding-left: 8px;
    }
}