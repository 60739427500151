.card {
    margin: 8px 12px 16px;
    border: 1px solid var(--bg-color-secondary);
    background-color: var(--bg-color-card);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0 var(--shadow);
    transition: all .3s ease-in-out;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.card:hover {
    transform: translateY(-8px);
    box-shadow: 0px 12px 30px 0px var(--shadow-hover);
    transition: all .3s ease-in-out;
}

.cardHead {
    width: 100%;
    height: 160px;
    overflow: hidden;
    background-color: var(--bg-color-card);
}

.cardBody {
    flex-grow: 2;
    padding: 6px 12px 0;
}

.cardBody > h4 {
    color: var(--bn-text);
}

.cardBody > p {
    font-size: 0.8rem;
    color: var(--secondary-text);
}

.cardFooter {
    display: flex;
    justify-content: space-around;
    padding: 12px 15%;
    font-size: 1.6rem;
}

.card svg {
    cursor: pointer;
    color:#777;
    transition: all .3s ease-in-out;
}

.card svg:hover {
    color: var(--bn-text);
    transition: all .3s ease-in-out;
}

.cardBody > h4 {
    font-weight: 500;
    margin: 8px 0;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .9;
    background-color: var(--bg-color-card-secondary)
}

