.h2 {
    position: relative;
    padding-bottom: 3px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--bn-text)
}

.h2:before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0px;
    background-color: var(--bg-color-secondary);
}

.h2:after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 30%;
    height: 2px;
    bottom: 0px;
    background-color: var(--primary-accent);
}

.blockTitle {
    padding: 8px 0 0 16px;
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 24px;
}