.card {
    display: flex;
    margin: 0px 8px 30px 0;
}

.icondiv {
    margin: 0 16px;
    font-size: 1.8rem;
    color: var(--primary-accent)
    
}

.textblock {
    padding: 0 8px;
    text-align: left;
}

.title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 6px;
    color: var(--bn-text);
}

.text {
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: var(--secondary-text)
}