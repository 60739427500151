.toggle {
    margin-top: 10px;
    margin-right: 16px;
}

.label {
    background-color: var(--bg-color-secondary);
    transition: background-color 0.2s linear;
    display: flex;
    position: relative;
    height: 26px;
    width: 50px;
    border-radius: 50px;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
}

.label:hover {
    background-color: skyblue;
    transition: background-color 0.3s linear;

}

.label:hover .faSun {
    opacity: 1;
    color: white;
    transition: color 0.3s linear;
}

.label:hover .faMoon {
    color: white;
    opacity: 1;
    transition: color 0.3s linear;
}

.ball {
    background-color: white;
    position: absolute;
    height: 22px;
    width: 22px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.3s linear;
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.checkbox:checked + label:hover {
    background-color: purple;
    transition: background-color 0.3s linear;

}

.checkbox:checked + label .ball {
    transform: translateX(24px);
}

.faMoon, .faSun {
    color: var(--secondary-text);
    opacity: .5;
    transition: color 0.3s linear;

}


@media screen and (max-width: 900px) {
    .toggle {
        margin-top: 9px;
        margin-right: 4px;
    }    

    .label {
        height: 22px;
        width: 40px;
        border-radius: 40px;
        padding: 5px;
        font-size: 0.85rem;
    }

    .ball {
        background-color: white;
        position: absolute;
        height: 18px;
        width: 18px;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        transition: transform 0.3s linear;
    }

    .checkbox:checked + label .ball {
        transform: translateX(18px);
    }
}